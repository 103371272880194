<template>
  <div class="way-mount">
    <el-button type="text" size="medium" @click.stop="onMount()">挂载设备</el-button>
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%">
      <!-- 查詢 -->
      <section class="table-search">
        <el-input v-model="tableParams.kw" placeholder="输入设备名称 / 外部地址" @input="kwChange" clearable class="mr15" />
        <el-select v-model="tableParams.inputStatus" placeholder="全部录入状态" @change="reload" clearable class="mr15">
          <el-option v-for="item in ObjToOpt(inputStatusObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.bindStatus" placeholder="全部绑定状态" @change="reload" clearable class="mr15">
          <el-option v-for="item in ObjToOpt(bindStatusObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.deviceStatus" placeholder="全部状态" @change="reload" clearable class="mr15">
          <el-option v-for="item in ObjToOpt(deviceStatusObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </section>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="设备名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.deviceName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备类型" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.deviceTypeName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备外部地址" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.deviceNumber || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备位置" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.deviceAddress || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否录入" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ inputStatusObj[scope.row.inputStatus] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="物理绑定状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ bindStatusObj[scope.row.bindStatus] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ deviceStatusObj[scope.row.deviceStatus] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click.stop="onBind(scope.row)"
              v-if="!scope.row.bindStatus && !scope.row.deviceStatus">绑定</el-button>
            <el-button type="text" size="medium" @click.stop="onRemove(scope.row)" v-if="scope.row.bindStatus">解绑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
          :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage" @current-change="onCurrentPage">
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {
  ObjToOpt,
} from "@/utils/utils";
import { getWayMountList, getBind, getRemove } from "@/api/device/device";
import { deviceStatusObj, deviceStatusColor, inputStatusObj, bindStatusObj } from "@/db/objs";
export default {
  props: ['row'],
  data() {
    return {
      ObjToOpt,
      deviceStatusObj,
      deviceStatusColor,
      inputStatusObj,
      bindStatusObj,
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        gatewayId: "",//网关ID
      },
    }
  },
  methods: {
    // 【请求】挂载列表
    getWayMountList() {
      let data = this.tableParams;
      getWayMountList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getWayMountList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getWayMountList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },


    // 【监听】编辑
    onMount() {
      this.editShow = true;
      this.editTitle = "挂载设备" + "→" + this.row.deviceName;
      this.tableParams.gatewayId = this.row.gatewayId;
      console.log('this.row: ', this.row);
      this.getWayMountList();
    },

    // 【监听】绑定
    onBind(row) {
      let data = {
        gatewayId: this.row.gatewayId,
        deviceId: row.deviceId
      };
      this.$confirm('你确定要绑定吗?', '绑定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getBind(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getWayMountList();
          }
        });
      });
    },

    // 【监听】解绑
    onRemove(row) {
      let data = {
        gatewayId: this.row.gatewayId,
        deviceId: row.deviceId
      };
      this.$confirm('你确定要解綁吗?', '解绑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getRemove(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getWayMountList();
          }
        });
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.way-mount {
  display: inline-flex;
  margin-left: .1rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  margin-bottom: .15rem;
}
</style>