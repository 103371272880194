<template>
  <div class="way-list">
    <section class="table-search">
      <div class="search-L">
        <el-select v-model="tableParams.merchantId" placeholder="请选择商户" @change="onMerchantSel" class="mr20">
          <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.shopId" placeholder="请选择店铺" @change="onShopSel">
          <el-option v-for="item in tableShopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增设备</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="设备名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="外部地址" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceNumber || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备IE地址" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceIeNumber || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{ 'color': deviceStatusColor[scope.row.deviceStatus] }">{{ deviceStatusObj[scope.row.deviceStatus]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)">拆除</el-button>
          <WayMount :row="scope.row" @reload="getAllWayList"></WayMount>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 设备名称 -->
        <el-form-item label="设备名称" label-width="120px" prop="deviceName">
          <el-input v-model.trim="params.deviceName" autocomplete="off" maxlength="8" placeholder="请填写设备名称"></el-input>
        </el-form-item>
        <!-- 外部地址 -->
        <el-form-item label="外部地址" label-width="120px" prop="deviceNumber">
          <el-input v-model.trim="params.deviceNumber" autocomplete="off" maxlength="20" placeholder="请填写外部地址"></el-input>
        </el-form-item>
        <!-- 设备IE地址 -->
        <el-form-item label="设备IE地址" label-width="120px" prop="deviceIeNumber">
          <el-input v-model.trim="params.deviceIeNumber" autocomplete="off" maxlength="20"
            placeholder="请填写设备IE地址"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllWayList,
  getWayAdd,
  getWayEdit,
  getWayDel,
} from "@/api/device/device"
import {
  getAutoCommercialList,
} from "@/api/commercial/commercial"
import {
  getIdShopList,
} from "@/api/commercial/shop"
import {
  ObjToOpt,
} from "@/utils/utils"
import { rules } from "@/db/rules";
import { merchantStatusObj, deviceStatusObj, deviceStatusColor } from "@/db/objs";
import WayMount from "@/views/device/child/WayMount.vue"
export default {
  components: {
    WayMount
  },
  data() {
    return {
      rules,
      ObjToOpt,
      merchantStatusObj,
      deviceStatusObj,
      deviceStatusColor,
      commercialList: [],// 商户列表
      tableShopList: [],//店铺列表
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      shopList: [],//店铺列表
      params: {
        deviceName: "", //服务名称
        deviceNumber: "",//外部地址
        deviceIeNumber: "",//设备IEEE地址
      },
    };
  },
  mounted() {
    this.getAutoCommercialList();// 【请求】商户下拉列表
  },
  methods: {
    // 【请求】列表
    getAllWayList() {
      let data = this.tableParams;
      getAllWayList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          if (res.data && res.data.length > 0) {
            this.tableParams.merchantId = res.data[0].merchantId;
            this.getTableIdShopList();
          }
        }
      });
    },

    // 【请求】店铺列表
    getIdShopList() {
      let data = {
        merchantId: this.params.merchantId
      };
      getIdShopList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
        }
      })
    },


    // 【请求】新增
    getWayAdd() {
      let data = this.params;
      getWayAdd(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload()
        }
      })
    },

    // 【请求】编辑
    getWayEdit() {
      let data = this.params;
      getWayEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getAllWayList();
        }
      })
    },

    // 【请求】筛选商户店铺列表
    getTableIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId
      };
      getIdShopList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
          if (res.data.length > 0) {
            this.tableParams.shopId = res.data[0].shopId;
          }
          this.getAllWayList();// 【请求】列表
        }
      })
    },

    // 【监听】筛选商户
    onMerchantSel() {
      this.tableParams.shopId = "";
      this.reload();
      if (this.tableParams.merchantId) {
        this.getTableIdShopList();
      } else {
        this.tableShopList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      this.$forceUpdate();
      this.reload();
    },


    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getAllWayList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getAllWayList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】弹框商户选择
    onMerchant() {
      this.getIdShopList();
      this.params.shopId = "";
    },

    // 【监听】弹框店铺选择
    onShop() {
      this.$forceUpdate()
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增设备";
      this.params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        gatewayId: "",
        deviceName: "",
        deviceNumber: "",
        deviceIeNumber: "",
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑服务";
      this.params = JSON.parse(JSON.stringify(row));
      this.getIdShopList();
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.gatewayId) {
            this.getWayEdit();
          } else {
            this.getWayAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        gatewayId: row.gatewayId
      }
      this.$confirm(`当前网关已关联${row.deviceCount || 0}个子设备，删除后子设备将无法工作是否确定拆除该设备?`, '拆除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getWayDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '拆除成功!'
            });
            this.getAllWayList();
          }
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.way-list {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
</style>